body,
html {
  font-family: 'Verdana' ;
   background: rgba(255, 247, 235, 0.774) !important;
background: linear-gradient(155deg, rgba(255, 247, 235, 0.774) 3%, rgba(194, 225, 238, 0.747) 88%) !important; 

}


.cov {
  background: rgba(255, 247, 235, 0.774) !important;
background-image: url('./blob-scene-haikei.svg') !important;
background-size: 15% !important;
background-repeat: no-repeat !important;
}

.auto-height {
  height: auto !important;
}

#navbar {
  display: block;
  transition: top 0.3s;
}
.test {
animation-name: animatetop;
animation-duration: .8s;
}
@keyframes animatetop {
  from {top: -600px; opacity: 0}
  to {top: 0; opacity: 1}
}

:root {
  --ck-z-default: 100;
  --ck-z-modal: calc( var(--ck-z-default) + 999 );
}


.text-justify {

  text-align: justify!important;
  font-size: small;
}




.truncate-4-lines {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
/* Ajustez la hauteur en fonction de la taille de la ligne */
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  min-height: calc(1.3em * 4.5);
  max-height: calc(1.3em * 4.5);
/* Ajustez la hauteur en fonction de la taille de la ligne */
  overflow: hidden;
  text-overflow: ellipsis;
}



.card-body {
  margin: 0px 0px !important;
  
}




