
*,::after,::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.animatesearch {
  width: 75%;
}
.animatesearch input[type=text] {
    width: 0px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
  background-image: url('../../assets/svg/search.svg');
  background-position: 10px 10px; 
  background-repeat: no-repeat;
  }
  
  /* When the input field gets focus, change its width to 100% */
  .animatesearch input[type=text]:focus {
    width: 100%;
  }

  .menu-categ:hover {
   background-color: rgba(65, 168, 224, 0.103)!important;
   border-radius: 15px;
   transition: .2s;
  }

  .drp {
    transition: .6s;
  }

  .footer {
    background-color: #282944;
    color:white
  }

  .bg-categ-permis {
    background-color: #9598b0;
    color: white;
    cursor: pointer;
    text-decoration: none;
    height:100%;
  }
  .bg-categ-permis:hover {
    background-color: #87899e;
    color: white;
   
  }
  .bg-categ-permis p {
    font-weight: 700;
  }


  @media (min-width: 768px) {
    .animate {
      animation-duration: 0.3s;
      -webkit-animation-duration: 0.3s;
      animation-fill-mode: both;
      -webkit-animation-fill-mode: both;
    }
  }
  @media screen and (max-width: 588px) {
    .responsive-text{
      width: 200px;
    }
  }
  @keyframes slideIn {
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
  
    100% {
      transform: translateY(0rem);
      opacity: 1;
    }
  
    0% {
      transform: translateY(1rem);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes slideIn {
    0% {
      -webkit-transform: transform;
      -webkit-opacity: 0;
    }
  
    100% {
      -webkit-transform: translateY(0);
      -webkit-opacity: 1;
    }
  
    0% {
      -webkit-transform: translateY(1rem);
      -webkit-opacity: 0;
    }
  }
  
  .slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
  }
.drop {
  top: 38px!important;
}

.nav-item:hover{
  background-color: rgba(83, 198, 233, 0.06);
}
 .btn-info {
  background-color: rgb(65, 169, 224)!important;
 }

.table-ty{
  height:366px!important;
}

* {
  scrollbar-width: thin !important;
}

.men .dropdown-menu {
  width: auto !important;
  max-width: 228px !important;
}