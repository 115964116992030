
/* ScrollPanelDemo.css */

 .p-scrollpanel.custombar1 .p-scrollpanel-wrapper {
    border-right: 10px solid whitesmoke;
}

 .p-scrollpanel.custombar1 .p-scrollpanel-bar {
    background-color: rgb(201, 221, 231);
    opacity: 1;
    transition: background-color .3s;
}

 .p-scrollpanel.custombar1 .p-scrollpanel-bar:hover {
    background-color: rgb(65, 169, 224);
}
