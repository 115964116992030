.section1 {
    background: rgba(255, 247, 235, 0.774) !important;
    background: linear-gradient(155deg, rgba(255, 247, 235, 0.774) 3%, rgba(194, 225, 238, 0.747) 88%) !important; 
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
}

.hero-sec {
    background-color: #E8F1F2!important;
    background-image: url('../../../assets/wall.jpg');
    background-position: right;
    background-size: cover;
    height: auto;
  
  
}

.carousel-dark{
    position: relative;
}
.hero-sec::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    height: auto;
    position: fixed;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.548);
}


.section2 {
    background-color: #E8F1F2!important;
    padding: 15px;
}

.bg-card {
    background-color: white!important;
}
.image-container img {
    max-height: 370px;
  }

.card-actu {
    margin: 0;
    padding:0;
}

.list-card {
    display: grid;
    grid-template-columns: repeat(auto-fit,320px);
    grid-template-rows: 1fr;
    grid-gap: 15px;
    justify-content: center;
}

.il .card-header {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
}

.message-content {
    margin: 45px 0px 45px 0;
}



@media screen and (max-width:776px) {
    .card-actu img {
        max-height: 180px;
    }
}


.row .cat:hover {
    background-color: rgba(111, 183, 183, 0.382) !important;
cursor: pointer;
transition: .5s;
}

.effet {
    transition: all .2s ease-in-out;
}

.effet:hover {
    transition: all .2s ease-in-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
   animation:miakatra .3s ease-in-out forwards;

}

@keyframes miakatra {
    0% {
        transform: scale(1);
    }
    50%{
        transform: scale(1.04);
    }
    100% {
        transform: scale(1.02);
    }
}

.anime-effet{
    overflow: hidden;
    position: relative;
   
}
.anime-effet::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.168);

}
.anime-effet:hover:after{
    
    background-color: rgba(0, 0, 0, 0.266);
    transition: .5s;

}



.anime-effet .card-img-top:hover{
    animation: zoom-in-zoom-out 1s ease-out forwards;
}

@keyframes zoom-in-zoom-out {
    0% {
    transform: scale(1);
    }
    100% {
    transform: scale(1.1);
    }
    }
.card {
    cursor: pointer;
}


.sary-fanehoana {
    background-image: url(../../../assets/image/imgtest/1.jpg);
    height: 300px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: antiquewhite;
    width: 100%;
    background-position: -1px center;
    border-radius: 15px;
}
.sary-fanehoana .soratra-anatiny {
    background-color: rgba(17, 19, 20, 0.59);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 15px;
    transition: background-color 0.5s ease 0s;
}
.sary-fanehoana .soratra-anatiny p {
    color: white;
    font-weight: 600;
    font-size: 1.5em;
    text-align: center;
}
.soratra-anatiny div svg {
    width: 7em;
    height: 7em;
    color: gold;
}

