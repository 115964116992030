
 .boutton-open-canvas {
    display: none;
  
    }   
@media screen and (max-width:767px) {
    .boutton-open-canvas {
    display: initial;
    }  
    .tab {
        z-index: 9000;
    } 
}
* {
    user-select: none;
}
.list-lesson li:hover {
color:blue;
cursor: pointer;
font-weight: bold;
}
